import 'vite/modulepreload-polyfill'
import './closient.scss';

// Make htmx globally available
import htmx from 'htmx.org';
(window as any).htmx = htmx;

import Alpine from 'alpinejs'

(window as any).Alpine = Alpine

Alpine.start()

console.log('Closient - %s', process.env.NODE_ENV)
